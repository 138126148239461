><template>
	<div>
		<v-row>
			<v-col cols="3" v-if="folderPaths.length">
				<v-select :items="folderPaths" v-model="folderPath"></v-select>
			</v-col>
			<v-col>
				<v-file-input 
				v-model="file" :accept="accept"
				:label="label"
				:hint="description"
				:persistent-hint="true"></v-file-input>
				<p class="text-right" v-if="ready">
					<v-btn color="success" @click="uploadFile">Upload File</v-btn>
				</p>
			</v-col>
		</v-row>
		<v-snackbar v-model="uploaded" light>
			{{message}}
			<v-btn color="green" text @click="uploaded = false">Close </v-btn>
		</v-snackbar>	
	</div>
</template>
<script>
	
	import { uploadFileToStorage } from "@/assets/storageFunctions.js";
	export default {
		name: "UploadField", 
		props: {
			exists: {type: Boolean},
			folderStem: {type: String },
			folderPaths: { type: Array, default: () => [] }, 
			fileName: { type: String },
			accept: { type: String }, 
			description: {type: String},
			label: {type:  String},
		},
		data: () => {
			return {
				dialog: false,
				file: false,
				folderPath: '',
				uploaded: false,
				message: "File Uploaded",
				ready: false
			}
		},
		computed: {
			filePath(){
				var fileName = this.fileName
				if( !this.fileName ){
					fileName = this.file.name;
				}
				var filePath = fileName;
				if( this.folderPath) {
					filePath = this.folderPath + "/" +  filePath;
				}
				if( this.folderStem ){
					filePath = this.folderStem + "/" + filePath;
				}
				return filePath;
			}
		},
		watch: {
			file(){
				this.ready = true;
			},
		},
		methods: {
			uploadFile(){
				const self = this;
			
				if( !self.file ){
					return false;
				}
				uploadFileToStorage( self.file, self.filePath ).then( file => {
					if( file ){
						this.message = "File Uploaded";
						self.uploaded = true;
						self.file = null;
						self.$emit('complete', file);
					}
				})
				
			},
		}, 
		created(){
			const self = this;
			if( this.folderPaths.length ){
				self.folderPath = self.folderPaths[0];
			}
			if( !self.filePath ){
				return false;
			}
		}
	}
</script>